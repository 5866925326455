import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, pairwise, startWith} from 'rxjs/operators';
import {Inject, Injectable, Optional} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Actions, ofActionDispatched, Select, Store} from '@ngxs/store';
import {RouterDataResolved} from '@ngxs/router-plugin';
import {isEqual} from 'lodash';
import {defaultRtTrackNavigationConfig, ModuleRelatedRegistration, RtTrackNavigationConfig, ScanUploadErrorData} from './symbols';
import {WINDOW} from '../universal/window/window.service';
import {RtTitlesConfig, RtTitlesConfigToken} from '../rt-titles/symbols';
import {AuthState} from '../auth/states/auth/auth.state';
import {User} from '../auth/symbols';


/** `dataLayer` field added by GTM inline script. Update `window` interface with it. */
interface WindowWithTrackData extends Window {
  dataLayer: Array<TrackNavigationData | ScanUploadErrorData | ModuleRelatedRegistration>;
}

/** Data interface for field `routerState` in `RouterDataResolved` payload. */
interface CustomRouterState {
  /** Url resolved by state. */
  url: string;
  /** Data provided for route in routing module. */
  data?: {
    /** Title provided for route in routing module. */
    title?: string;
  };
}

/** Raw data generated for simplified use. */
interface RawNavigationData {
  /** Route `url` without domain. */
  shortPath: string;
  /** Route full `url` */
  url: string;
  /** Title for page at current url. */
  title: string;
  /** Data about current user. */
  user?: User;
}


/**
 * DataLayer object interface, requested for Google Tag Manager.
 * Such object should be pushed to `window.dataLayer` on every navigation change.
 */
interface TrackNavigationData {
  /** Event name for track object. */
  event: 'Loaded a Page';
  /** Url of loaded page. Do not contain domain. */
  path: string;
  /** Url of previous page. Should contain full url (domain included). */
  referrer: string;
  /** Title of current page. */
  title: string;
  /** Url of current page. Should contain full url (domain included). */
  url: string;
  /** Current user ID. Should be added if user is authenticated. */
  userID?: string;
}

/**
 * Describes module-related URLs. If after registration URL reached from it -> user came for this feature.
 */
enum ModuleRelatedRegistrationUrl {
  GENERAL = 'auth/sign-up',
  GENETICS = 'auth/sign-up/genetics',
  UPLOAD_SCAN = 'auth/sign-up/upload-scan',
}

/**
 * Link for redirect user after success registration.
 */
const AfterRegistrationUrl = 'auth/not-verified';


/**
 * Single page application have problem while working with Google Tag Manager:
 * App does not emit event as changing url does not cause page reloading.
 * In usual app it emits event because HTML files with GTM script are initialized every navigation.
 *
 * This service provide function for emitting event on every URL change.
 */
@Injectable()
export class RtTrackUserEventsService {
  @Select(AuthState.getUser) user$: Observable<User>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private store: Store,
    private actions$: Actions,
    @Optional() @Inject(WINDOW) private _window: WindowWithTrackData,
    @Optional() @Inject(RtTitlesConfigToken) private injectedConfig: RtTitlesConfig,
  ) {
    this.window = _window;
    // Merge with default config & save in local variable (so it will be never undefined).
    this.config = Object.assign(defaultRtTrackNavigationConfig, injectedConfig);
  }

  /**
   * Module configs. Contain data provided as injection (and/or set by default.)
   */
  private config: RtTrackNavigationConfig;

  /**
   * Global JavaScript `window` object.
   */
  private readonly window: WindowWithTrackData;

  /**
   * Start tracking user navigation.
   * (Add subscription over route url observable, that never unsubscribe, so should be called once).
   * NOTE! Global `window` object is used (may cause an error with prerender).
   */
  public initializeNavigationTracking(): void {
    /** Contains actual data about route (guarantee that route is resolved once next value emitted). */
    const currentRouteData$: Observable<RawNavigationData> = combineLatest([
      this.actions$.pipe(ofActionDispatched(RouterDataResolved), map(payload => payload.routerState as CustomRouterState)),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
    ]).pipe(
      // Wait until url will be resolved.
      // (`RouterDataResolved` fires before `NavigationEnd`, so otherwise `window.location.href` will be not actual).
      filter(([routerState, routerEvent]) => {
        const navigationEndEvent = (routerEvent as Pick<NavigationEnd, keyof NavigationEnd>);
        return routerState.url === navigationEndEvent.urlAfterRedirects;
      }),

      // Switch to data from `RouterDataResolved` action (it contains all data we need).
      map(([routerState, _]) => routerState),

      // Prevent next value emit if url were not changed.
      distinctUntilChanged(isEqual),

      // Add info about full url path from `window.location.href`.
      map((event) => {
        return {
          shortPath: event.url,
          url: this.window.location.href,
          title: this.config.TITLE_FORMATTER(event.data?.title),
        } as RawNavigationData;
      }),

      // First router resolve by queue is after subscription. So, provide first data manually.
      startWith({
        shortPath: this.window.location.pathname,
        url: this.window.location.href,
        title: this.title.getTitle(),
      }),
    );

    combineLatest([currentRouteData$, this.user$]).pipe(
      // Wait until user data is resolved (even if user is not signed this variable will contain object).
      filter(([_, user]) => !!user),
      // Merge route and user data.
      map(([rawRouteData, user]) => {
        return {
          ...rawRouteData,
          user,
        };
      }),
      // Add initial data; otherwise first navigation will not cause event (because of `pairwise` operator).
      startWith({
        shortPath: null,
        url: null,
        title: null,
        user: null,
      } as RawNavigationData),
      // Collect data about previous navigation and provide it with current simultaneously.
      pairwise<RawNavigationData>(),
    ).subscribe(([prev, current]) => {
      const trackNavigationData: TrackNavigationData = {
        event: 'Loaded a Page',
        path: current.shortPath,
        referrer: prev.url,
        title: current.title,
        url: current.url,
      };

      // Catch if user has module related registration.
      this.catchSpecificRegistration(prev.url, current.url);

      // Add userID if user is authenticated.
      const user = current.user;
      if (user?.uid && (user?.is_authenticated || user?.is_authenticated_2fa)) {
        trackNavigationData.userID = user.uid.toString();
      }

      // Send data about navigation.
      this.addCustomEventToGTM(trackNavigationData);
    });
  }

  /**
   * Provide data to Google Tag Manager script.
   * Data will be sent to Google Analytics by script automatically.
   * NOTE! Global `window` object is used (may cause an error with prerender).
   */
  public addCustomEventToGTM(eventData: TrackNavigationData | ScanUploadErrorData | ModuleRelatedRegistration): void {
    // Create/update `dataLayer` using provided data.
    if (this.window.dataLayer?.length) {
      this.window.dataLayer.push(eventData);
    } else {
      this.window.dataLayer = [eventData];
    }
  }

  /**
   * Generate unique GTM Event if redirect url contain data about specific module-related registration.
   */
  public catchSpecificRegistration(prevUrl: string, nextUrl: string): void {
    // Prevent any actions if previous or next url not defined.
    if (typeof prevUrl !== 'string' || typeof nextUrl !== 'string') {
      return;
    }

    // Try to find module related substring.
    const isGeneralRelated = prevUrl.includes(ModuleRelatedRegistrationUrl.GENERAL);
    const isGeneticsRelated = prevUrl.includes(ModuleRelatedRegistrationUrl.GENETICS);
    const isUploadScanRelated = prevUrl.includes(ModuleRelatedRegistrationUrl.UPLOAD_SCAN);

    // Prevent any actions if no substring found.
    if (!isGeneralRelated && !isGeneticsRelated && !isUploadScanRelated) {
      return;
    }

    // Try to define if user just registered.
    const isUserRegistered = nextUrl.includes(AfterRegistrationUrl);

    // Prevent any actions if second url not register-related.
    if (!isUserRegistered) {
      return;
    }

    // Create GTM event like genetic-related.
    const relatedRegistrationEvent: ModuleRelatedRegistration = {
      event: 'Registration',
      type: 'General',
      details: 'User registered via general sign up link.',
    };

    // Change to genetics scan related event.
    if (!!isGeneticsRelated) {
      relatedRegistrationEvent.type = 'Genetics';
      relatedRegistrationEvent.details = 'User registered via genetic-related feature link.';
    }

    // Change to upload scan related event.
    if (!!isUploadScanRelated) {
      relatedRegistrationEvent.type = 'Upload a Scan';
      relatedRegistrationEvent.details = 'User registered via upload a scan related feature link.';
    }

    // Provide generated event to Google Tag Manager.
    this.addCustomEventToGTM(relatedRegistrationEvent);
  }
}
