import {ModuleWithProviders, NgModule} from '@angular/core';
import {RtLoadProgressConfig, RtLoadProgressConfigToken} from './symbols';
import {RtLoadProgressService} from './rt-load-progress.service';


@NgModule()
export class RtLoadProgressModule {
  static forRoot(config?: RtLoadProgressConfig): ModuleWithProviders<RtLoadProgressModule> {
    return {
      ngModule: RtLoadProgressModule,
      providers: [
        {
          provide: RtLoadProgressConfigToken,
          useValue: config,
        },
        RtLoadProgressService,
      ],
    };
  }
}
