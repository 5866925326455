import {InjectionToken} from '@angular/core';

export interface CustomRouteModel {
  data?: RouteData;
  url: string;
}

export interface RouteData {
  title?: string;
  meta?: MetaTag[];
}


export interface MetaTag {
  name: string;
  content: string;
}

/** Configs for `RtTitlesModule`. */
export interface RtTitlesConfig {
  /**
   * Default title for page.
   * This title will be set for page if no title provided.
   * This title will be added to the end of title string if any data provided.
   */
  PAGE_TITLE?: string;
  /**
   * Default title separator.
   * Will be added between each page subtitle.
   */
  TITLE_PAGE_SEPARATOR?: string;
  /**
   * Default array of meta tags.
   * Will be set as meta if no data for meta provided.
   */
  META_TAGS?: MetaTag[];
  /**
   * Formatter for title (function).
   * Transform provided data into single string (page title).
   */
  TITLE_FORMATTER?: (parts: void | string | string[], siteName?: string, titleSeparator?: string) => string;
}

export const defaultRtTitlesConfig: RtTitlesConfig = {
  PAGE_TITLE: 'Page',
  TITLE_PAGE_SEPARATOR: ' - ',
  META_TAGS: [],
  /**
   * Default formatter:
   * For array: join all strings.
   * For string: return same string.
   * For empty: return `Page`.
   */
  TITLE_FORMATTER: (parts: void | string | string[]) => Array.isArray(parts) ? parts.join(' ') : parts ? parts : 'Page',
};

export const RtTitlesConfigToken = new InjectionToken<string>('RtTitlesConfig');
