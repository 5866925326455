import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtDynamicScriptsService } from './rt-dynamic-scripts.service';
import { RtDynamicScriptsServiceConfig, DYNAMIC_SCRIPTS_CONFIG_TOKEN } from './rt-dynamic-scripts.config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
})
export class RtDynamicScriptsModule {
  constructor (@Optional() @SkipSelf() parentModule?: RtDynamicScriptsModule) {
    if (parentModule) {
      throw new Error(
        'RtDynamicScriptsModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: RtDynamicScriptsServiceConfig): ModuleWithProviders<RtDynamicScriptsModule> {
    return {
      ngModule: RtDynamicScriptsModule,
      providers: [
        { provide: DYNAMIC_SCRIPTS_CONFIG_TOKEN, useValue: config },
        RtDynamicScriptsService
      ]
    };
  }
}
