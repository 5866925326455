import {InjectionToken} from '@angular/core';


/** Configs for `RtTrackUserEventsModule`. */
export interface RtTrackNavigationConfig {
  /**
   * Formatter for title (function).
   * Transform provided data into single string (page title).
   */
  TITLE_FORMATTER?: (parts: void | string | string[], siteName?: string, titleSeparator?: string) => string;
}

/** Default config for `RtTrackUserEventsModule`. Used if no configs provided. */
export const defaultRtTrackNavigationConfig: RtTrackNavigationConfig = {
  /**
   * Default formatter:
   * For array: join all strings.
   * For string: return same string.
   * For empty: return `Page`.
   */
  TITLE_FORMATTER: (parts: void | string | string[]) => Array.isArray(parts) ? parts.join(' ') : parts ? parts : 'Page',
};

/** Injection token. Used for provide configs from imports in module to `RtTrackNavigation` services. */
export const RtTrackNavigationConfigToken = new InjectionToken<string>('RtTrackNavigationConfig');


/** General interface for tracking any upload errors via GTM. */
interface UploadErrorsData {
  /** Name of Google Tag Manager event. */
  event: 'Upload Errors';
  /** Type of caused error. Specify the details about error. */
  type: string;
}

export interface ScanUploadErrorData extends UploadErrorsData {
  /** Upload a scan have declared specified type. */
  type: 'Upload a Scan';
  /** Indicate if upload failed because such scan was upload earlier. */
  duplicated: boolean;
  /** Contain error text message. */
  details: string;
}


export interface ModuleRelatedRegistration {
  /** Registration event have declared specified event. */
  event: 'Registration';

  /** Describes relation to specific module. */
  type: 'General' | 'Genetics' | 'Upload a Scan';

  /** Contain additional data about event. */
  details: string;
}

