@if (display$ | async) {
  <div class="cookie-popup d-flex flex-column flex-sm-row">
    <div class="agreement flex-grow-1">
      <h6>Cookie Policy</h6>
      <p>
        This website uses cookies to improve our site's user-friendliness and to measure where our visitors come from.
        By continuing to use our website, you agree to our use of cookies.
        You can find more information about our <a routerLink="/cookies">cookie policies</a>
      </p>
    </div>
    <div class="i-agree"><span class="button button-primary" (click)="iAgree()">Yes, I agree</span></div>
  </div>
}

