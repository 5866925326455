<div>
  <owl-carousel-o [options]="customOptions" (translated)="onSlideChange($event)" #storiesCarousel>
    @for (itemsChuck of formedSlidesData; track itemsChuck; let i = $index) {
      <ng-template carouselSlide>
        <div class="carousel-page">
          @for (item of itemsChuck; track item) {
            @if (item.name) {
              <div class="carousel-item">
                <div class="carousel-item__icon">
                  “
                </div>
                <div class="carousel-item__text" [innerHtml]="item.text"></div>
                <div class="carousel-item__separator"></div>
                <div class="carousel-item__name">
                  {{item.name}}
                </div>
                <div class="carousel-item__since">
                  BrainKey user since {{item.since}}
                </div>
              </div>
            } @else {
              <div class="carousel-item carousel-item_placeholder"></div>
            }
            <ng-template #placeholder>
              <div class="carousel-item carousel-item_placeholder"></div>
            </ng-template>
          }
        </div>
      </ng-template>
    }
  </owl-carousel-o>
</div>
