import {Country} from './symbols';


export class LoadCountry {
  static readonly type = '[Geo] Load Country';
}

export class LoadCountrySuccess {
  static readonly type = '[Geo] Load Country Success';
  constructor(public country: Country) {}
}
