import {NgModule} from '@angular/core';
import {HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {RtHammerJsConfig} from './rt-hammer-js-config';

@NgModule({
  imports: [HammerModule],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: RtHammerJsConfig,
    },
  ],
})
export class RtHammerJsModule {
}
