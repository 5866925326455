import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';


@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  /**
   * Intercept all HTTP Client requests and provide token to them.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.store.selectSnapshot(state => state?.rtJwtAuth?.accessToken);

    request = token ? request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    }) : request;
    return next.handle(request);
  }

}
