import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PagesComponent} from './pages.component';
import {LayoutModule} from '../layout/layout.module';


@NgModule({
  imports: [
    LayoutModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    PagesComponent,
  ],
  exports: [
    PagesComponent,
  ],
})
export class PagesModule {
}
