import {ModuleWithProviders, NgModule} from '@angular/core';
import {RtTitlesConfig, RtTitlesConfigToken} from './symbols';
import {NgxsModule} from '@ngxs/store';
import {RtTitlesState} from './rt-titles.state';


@NgModule({
  imports: [NgxsModule.forFeature([RtTitlesState])],
})
export class RtTitlesModule {
  static forRoot(config?: RtTitlesConfig): ModuleWithProviders<RtTitlesModule> {
    return {
      ngModule: RtTitlesModule,
      providers: [
        {
          provide: RtTitlesConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
