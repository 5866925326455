import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Country} from './symbols';


@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor(public http: HttpClient) {}

  fetchCountry(): Observable<Country> {
    return this.http.get<Country>('/api/geo/country/');
  }
}
