import {Injectable} from '@angular/core';
import {HammerGestureConfig} from '@angular/platform-browser';
import * as Hammer from '@egjs/hammerjs';

@Injectable({
  providedIn: 'root'
})
export class RtHammerJsConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL},
  } as any;
}
