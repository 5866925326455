import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';

/**
 * Describes data interface for storing a route.
 */
interface RouteStorageObject {
  /** Useful for determining whether a route should be attached (see this.shouldAttach). */
  snapshot: ActivatedRouteSnapshot;

  /** Offered up by `this.retrieve`. Used for attaching the stored route */
  handle: DetachedRouteHandle;
}

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  /**
   * Object which store RouteStorageObjects indexed by keys.
   * Each key is a route path (as in route.routeConfig.path).
   */
  storedRoutes: Map<string, RouteStorageObject> = new Map();

  /**
   * Check the route data property `shouldReuseRoute` to decide when the route should be stored.
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig.data?.shouldReuseRoute;
  }

  /**
   * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment.
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRoutes.set(route.routeConfig.path, {
      snapshot: route,
      handle,
    });
  }

  /**
   * Determines whether there is a stored route and, if there is, whether it should be rendered in place of requested route.
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // This will be true if the route has been stored before.
    return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path);
  }

  /**
   * Finds the locally stored instance of the requested route, if it exists, and returns it.
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // Return null if the path does not have a routerConfig or  there is no stored route for that routerConfig.
    if (!route.routeConfig || !this.storedRoutes.get(route.routeConfig.path)) {
      return null;
    }

    // Returns handle when the route.routeConfig.path is already stored.
    return this.storedRoutes.get(route.routeConfig.path).handle;
  }

  /**
   * Determines whether the current route should be reused.
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
