/**
 * Format title using provided data.
 *
 * Examples:
 * [input] () => [output] `siteName`
 * [input] (['a', 'b'], 'Site', ' | ') => [output] `a | b | Site`
 */
export function formatTitle(
  parts: void | string | string[],
  siteName: string = 'BrainKey',
  titleSeparator: string = ' | ',
): string {
  // No parts provided => return siteName.
  if (!parts) {
    return siteName;
  }

  // Transform any provided data to readable array.
  if (!Array.isArray(parts)) {
    parts = [parts];
  } else {
    parts = [...parts]; // remove readOnly flag
  }

  // Add siteName to end of the subtitle array.
  parts.push(siteName);

  // Create string using subtitles array, add separator between each.
  return parts.join(titleSeparator);
}
