import {Injectable, isDevMode} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {LoadCountry, LoadCountrySuccess} from './cookies.actions';
import {GeoStateModel} from './symbols';
import {CookiesService} from './cookies.service';
import {RtPlatformService} from '../../../rt-platform/rt-platform.service';


@State<GeoStateModel>({
  name: 'geo',
  defaults: {
    country: null,
  }
})

@Injectable()
export class CookiesState implements NgxsOnInit {
  static gdprCountries = new Set([
    'AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT',
    'SK', 'SI', 'ES', 'SE', 'GB', 'HR', 'DE', 'GR', 'RO'
  ]);

  @Selector()
  static getCountryCode(state: GeoStateModel): string {
    return state.country ? state.country.country_code : null;
  }

  @Selector()
  static isGdprCountry(state: GeoStateModel): boolean {
    if (!state.country || !state.country.country_code) {
      return null;
    }
    return CookiesState.gdprCountries.has(state.country.country_code);
  }

  constructor(private geo: CookiesService, private platform: RtPlatformService) {
  }

  ngxsOnInit({dispatch}: StateContext<GeoStateModel>) {
    if (this.platform.isBrowser && !isDevMode()) { // api not present locally, do not send request in dev mode
      dispatch(new LoadCountry());
    }
  }

  @Action(LoadCountry)
  loadCountry({dispatch}: StateContext<GeoStateModel>) {
    this.geo.fetchCountry().subscribe(
      country => dispatch(new LoadCountrySuccess(country)),
      err => console.warn(err),
    );
  }

  @Action(LoadCountrySuccess)
  loadCountrySuccess({patchState}: StateContext<GeoStateModel>, {country}: LoadCountrySuccess) {
    patchState({country});
  }
}
