<div class="pages">
  <div class="app-header">
    <app-general-header></app-general-header>
  </div>

  <div class="app-content">
    <router-outlet></router-outlet>
  </div>

  <div class="app-footer">
    <app-footer></app-footer>
  </div>
</div>
