import {InjectionToken} from '@angular/core';

/**
 * Describes a model for user country data.
 */
export interface Country {
  /** Short country code (usually contains two uppercase latin letters). */
  country_code: string;

  /** Full country name. */
  country_name: string;
}

/**
 * Describes a model for geo-position user data.
 */
export interface GeoStateModel {
  country: Country;
}

/**
 * Describes available configs for cookies module.
 */
export interface CookiesModuleConfig {
  /**
   * Key for cookies service that indicates if cookies were accepted.
   */
  COOKIES_AGREED_KEY?: string;

  /**
   * List of routes where the cookies modal should not be displayed.
   */
  EXCLUDE_ROUTES_LIST?: string[];
}

/**
 * Default configs for cookies module.
 */
export const defaultCookiesModuleConfig: CookiesModuleConfig = {
  COOKIES_AGREED_KEY: 'cookie-policy-agreed',
  EXCLUDE_ROUTES_LIST: [],
};

/**
 * Contain token for injecting configs into cookies module.
 */
export const CookiesModuleConfigToken = new InjectionToken<string>('CookiesModuleConfig');



