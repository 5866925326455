import {ModuleWithProviders, NgModule} from '@angular/core';
import {RtTrackNavigationConfig, RtTrackNavigationConfigToken} from './symbols';
import {RtTrackUserEventsService} from './rt-track-user-events.service';


@NgModule({
  providers: [
    RtTrackUserEventsService,
  ]
})
export class RtTrackUserEventsModule {
  static forRoot(config?: RtTrackNavigationConfig): ModuleWithProviders<RtTrackUserEventsModule> {
    return {
      ngModule: RtTrackUserEventsModule,
      providers: [
        RtTrackUserEventsService,
        {
          provide: RtTrackNavigationConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
