import {Inject, Injectable, Optional} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Action, State, StateContext} from '@ngxs/store';
import {RouterDataResolved} from '@ngxs/router-plugin';
import {CustomRouteModel, defaultRtTitlesConfig, MetaTag, RtTitlesConfig, RtTitlesConfigToken} from './symbols';


@State<void>({
  name: 'rtTitles',
})
@Injectable()
export class RtTitlesState {
  private config: RtTitlesConfig;

  constructor(
    private titleService: Title,
    private meta: Meta,
    @Optional() @Inject(RtTitlesConfigToken) private injectedConfig: RtTitlesConfig,
  ) {
    // Merge with default config & save in local variable (so it will be never undefined).
    this.config = Object.assign(defaultRtTitlesConfig, injectedConfig);
  }

  @Action(RouterDataResolved)
  setTitleAndMeta(ctx: StateContext<void>, {routerState}: RouterDataResolved<CustomRouteModel>) {
    const data = routerState.data;
    const url = routerState.url;

    // Set provided title (use default if no data provided).
    const title = data?.title ? data.title : '';
    this.titleService.setTitle(this.config.TITLE_FORMATTER(title, this.config.PAGE_TITLE, this.config.TITLE_PAGE_SEPARATOR));

    // Set provided meta (use default if no data provided).
    const meta: MetaTag[] = data?.meta?.length ? data.meta : this.config.META_TAGS;
    if (!Array.isArray(meta)) {
      throw new Error('Meta provided for route should be formatted as array.');
    }

    // Iterate over provided tags, set each as meta.
    meta.forEach(metaTag => {
      if (metaTag && Object.keys(metaTag).length) {
        this.meta.updateTag({...metaTag});
      } else {
        throw new Error(`No data were provided for meta tag at route '${url}'`);
      }
    });
  }
}
